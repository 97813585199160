import * as React from "react";
import { withLayout, LayoutProps } from "../components/Layout";
import { Segment, Container, Grid, Header, Icon, Item } from "semantic-ui-react";

const ServicesPage = (props: LayoutProps) => {
  return (
    <div className="page services-page">
      <Segment vertical textAlign="center" className="masthead">
        <Container>
          <Header as="h1" inverted>
            Нашите услуги
          </Header>
          <br />
        </Container>
      </Segment>
      <Segment vertical className="stripe ">
        <Grid
          columns="2"
          relaxed
          stackable
          className="container services-container"
        >
          <Grid.Row>
            <Grid.Column>
              <Item.Group>
                <Item >
                  <Item.Image  size="small">
                    <Icon name="lightning" size="massive" />
                  </Item.Image>
                  <Item.Content>
                    <Item.Header >
                      Електрически инсталации
                    </Item.Header>
                    <Item.Description  > 
                      Изграждане и ремонт на електрически инсталации. Монтаж и преместване на ключове, контакти, розетки, разпределителни кутии и други.
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Grid.Column>
            <Grid.Column>
            <Item.Group>
                <Item >
                  <Item.Image  size="small">
                    <Icon name="lightbulb" size="massive" />
                  </Item.Image>
                  <Item.Content>
                    <Item.Header >
                      Осветление
                    </Item.Header>
                    <Item.Description  > 
                      Монтаж на външни, вътрешни и аварийни осветителни тела. Независимо дали става дума за лунички в кухнята или нови дворни лампи, ние можем да ви помогнем.
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
              
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <br />
              <br />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row >
            <Grid.Column>
            <Item.Group>
                <Item >
                  <Item.Image  size="small">
                    <Icon name="window maximize outline" size="massive" />
                  </Item.Image>
                  <Item.Content>
                    <Item.Header >
                      Разпределителни табла
                    </Item.Header>
                    <Item.Description  > 
                      Монтаж и ремонт на електрически табла. Смяна на прекъсвачи, предпазители и ДТЗ.
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Grid.Column>
            <Grid.Column>
            <Item.Group>
                <Item >
                  <Item.Image  size="small">
                    <Icon name="archive" size="massive" />
                  </Item.Image>
                  <Item.Content>
                    <Item.Header >
                      Електроуреди
                    </Item.Header>
                    <Item.Description  > 
                      Монтаж и свързване към ел. инсталацията на фурни, аспиратори, котлони и други битови електроуреди.
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Grid.Column>

          </Grid.Row>
        </Grid>
      </Segment>
    </div>
  );
};
export default withLayout(ServicesPage);

export const pageQuery = graphql`
query Services {
services: allMarkdownRemark(
  filter: {
    frontmatter: {draft: {ne: true}},
    fileAbsolutePath: {regex: "/services/"},
  },
  sort: {order: DESC, fields: [frontmatter___updatedDate]},
  limit: 4
) {
  edges {
    node {
      fields {
        slug
      }
      timeToRead
      frontmatter {
        title
        author {
          id
        }
      }
    }
  }
}
}
`;
